import React from 'react'

const CodeExample = ({ className, children }) => {
  let classNames = ['code-example']

  if (className) {
    classNames.push(className)
  }

  return (
    <div className={classNames.join(' ')}>
      <figure
        className="code-example-render"
        dangerouslySetInnerHTML={{ __html: children}}
      ></figure>

      <pre className="code-example-source">
        {children.trim()}
      </pre>
    </div>
  )
}

export default CodeExample
