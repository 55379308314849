import React from 'react'
import { Card } from 'react-bootstrap'
import PropTypes from 'prop-types'

import Swatch from './swatch'

const SwatchCard = ({ title, color, hex }) => {
  return (
    <Card>
      <Swatch color={color} />

      <Card.Body>
        <Card.Title>
          {title}
        </Card.Title>

        <Card.Text>
          <code>#{hex}</code><br />
          <code>map-get($ss-colors, '{color}')</code>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

SwatchCard.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  hex: PropTypes.string.isRequired,
}

export default SwatchCard
