import React from 'react'
import PropTypes from 'prop-types'

const Swatch = ({ color }) => {
  const className = `swatch swatch-${color}`

  return (
    <figure className={className}></figure>
  )
}

Swatch.propTypes = {
  color: PropTypes.string.isRequired,
}

export default Swatch
